/* eslint-disable @typescript-eslint/no-explicit-any */
import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from '../../environments/environment';
@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  // TODO

  iv = CryptoJS.enc.Base64.parse(environment.iv); // giving empty initialization vector
  key = CryptoJS.SHA256(environment.key); // hashing the key using SHA256
  encryptedString: any;
  secretKey: any = 'LOGIBIDS_ADMIN';

  encryptData(data:any):any {
    // const currentTimestamp = dayjs.valueOf();
    // data['date'] = currentTimestamp;
    this.encryptedString = CryptoJS.AES.encrypt(JSON.stringify(data), this.key, {
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return this.encryptedString.toString();
  }

  decryptData(encrypted:any):any {
    const decrypted = CryptoJS.AES.decrypt(encrypted, this.key, {
      iv: this.iv,
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7
    });
    return decrypted.toString(CryptoJS.enc.Utf8);
  }

  encryptLocalStorage(data: any, key: string):any {
    const encyrpted = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      this.secretKey
    ).toString();
    localStorage.setItem(key, encyrpted);
  }

  decryptLocalStorage(key: string):any {
    if (key) {
      const data = localStorage.getItem(key);
      if (data) {
        const bytes = CryptoJS.AES.decrypt(data, this.secretKey);
        const recieved = bytes.toString(CryptoJS.enc.Utf8);
        return JSON.parse(recieved);
      }
    }
  }
}
